<template>
  <div class="register-form">
    <el-form @submit.prevent="handleRegister" label-position="top">
      <el-form-item label="用户名">
        <el-input v-model="registerData.username" />
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="registerData.password" show-password/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { request } from '@/utils/apiClient';
import router from '@/router';
import { ElMessage } from 'element-plus';

export default {
  setup() {
    const registerData = ref({
      username: '',
      password: '',
    });

    const handleRegister = async () => {
      try {
        const response = await request('api/user/register_user/', 'post', {}, registerData.value);
        if (response.status === 200) {
          ElMessage.success('注册成功');
          router.push('/login')
        } else {
          ElMessage.error(response?.data?.message || '注册失败');
        }
      } catch (error) {
        console.error('注册错误:', error);
        ElMessage.error('注册时发生错误');
      }
    };

    return {
      registerData,
      handleRegister,
    };
  },
};
</script>
