<template>
  <div class="home">
    <novel-list />
  </div>
</template>

<script>
import NovelList from '@/components/novel/NovelList.vue'

export default {
  name: 'HomeView',
  components: {
    NovelList,
  }
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>
