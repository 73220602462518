<template>
  <div class="novel-list"  v-infinite-scroll="handleScroll">
    <el-row :gutter="12" style="width: 100%;">
      <el-col
        :xs="24" :sm="24" :md="12" :lg="12" :xl="8"
      >
        <el-input 
          :suffix-icon="Search" 
          v-model="query" 
          placeholder="您想看关于什么的内容？请在这里告诉我们，我们将为您查找。"
          clearable
        />
      </el-col>
    </el-row>
    <el-row :gutter="12" style="width: 100%;">
      <el-col 
        v-for="novel in allDisplayedNovels" 
        :key="novel.id" 
        :xs="24" :sm="24" :md="12" :lg="8" :xl="6"
      >
        <el-card 
          style="padding: 0 !important; margin-top: 20px;"
          @click="() => handleClickNovel(novel.id)"  
        >
          <div class="novel-card">
            <el-image
              :src="`${BASE_URL}${novel?.cover}`"
              style="width: 110px; height: 160px;"
              fit="cover"
              :alt="novel.title"
              :lazy="true"
            />
            <div class="card-content">
              <h3 class="novel-title">{{ novel.title }}</h3>
              <p class="author">{{ novel.author }}</p>
              <p class="intro">{{ novel.intro }}</p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-skeleton v-if="loading"/>
  </div>
  <el-empty v-if="allDisplayedNovels.length < 1" description="暂无小说上架" />
</template>

<script setup>
import { ref, onMounted, computed, onActivated, watch} from 'vue';
import { request, BASE_URL } from '@/utils/apiClient';
import debounce from '@/utils/debounce';

import { ElMessage } from 'element-plus';
import { Search } from '@element-plus/icons-vue';

import router from '@/router'

const novels = ref([]);
const loading = ref(false);
const totalPages = ref(1);
let currentPage = ref(1);
const allDisplayedNovels = computed(() => novels.value);
const isJumping = ref(false)
const query = ref('');

const handleSearch = debounce(loadMoreNovels, 400);

watch(query, () => {
  currentPage.value = 1;
  handleSearch();
});

onMounted(async () => {
  await loadMoreNovels(1);
});

onActivated(async () => {
  await loadMoreNovels();
});

async function loadMoreNovels(page = 1) {
  if (page > totalPages.value) {
    return;
  }

  if (loading.value) return;
  loading.value = true;
  try {
    const response = await request('api/novel/novels/', 'get', { 
      page,
      query: query.value ?? '',
      is_search: !!query.value
    });
    const moreNovels = response?.data?.novels ?? []
    if (page > 1) {
      novels.value = [...novels.value, ...moreNovels];
    } else {
      novels.value = [...moreNovels]
    }
    const total = response?.data?.total_pages ?? 1
    totalPages.value = total
    currentPage.value = response?.data?.current_page ?? 1;
  } catch (error) {
    console.error('加载更多小说失败', error);
  } finally {
    loading.value = false;
  }
}

function handleScroll() {
  loadMoreNovels(currentPage.value + 1);
}

async function handleClickNovel(novel) {
  if (isJumping.value) return
  try {
    isJumping.value = true
    const response = await request('api/record/reading_process/', 'get', {
      'novel_id': novel
    })

    if (!response?.data?.id) {
      ElMessage.info('作者还没有开始编写这部小说呢，暂时还看不了哦')  
    }

    router.push(`/chapter/${response?.data?.id}/details`)
  } catch (e) {
    if (e?.response?.status === 401) {
      router.push(`/login?jump_to=${novel}&type=novel`)
    } else if (e?.response?.status === 404) {
      ElMessage.info('作者还没有开始编写这部小说呢，暂时还看不了哦')  
    } else {
      ElMessage.info('发生了一些错误哦，我们会尽快解决，向您道歉')
    }
  } finally {
    isJumping.value = false
  }
}
</script>

<style lang="less" scoped>
.novel-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
}

.novel-card {
  width: 100%;
  height: 160px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  .card-content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 6px;
    height: 100%;
    flex: 1;

    .novel-title {
      font-size: 16px;
      line-height: 14px !important;
      font-weight: 600;
      margin-block-end: 6px;
    }

    p {
      font-size: 12px;
      line-height: 12px !important;
    }

    .author {
      margin-bottom: 0;
    }

    .date {
      font-size: 12px;
      line-height: 12px !important;
      color: grey;
      text-decoration: underline;
    }

    .intro {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>

<style>
.el-card__body {
  padding: 0 !important;
}
</style>
