<template>
  <div class="out-container-app">
    <div class="bar" id="app-root-bar">
      <div class="logo-box">
        <img src="./assets/logo.png" @click="() => goToHome()" alt="logo" class="logo" />
        <router-link to="/">首页</router-link>
      </div>
      <div class="status-box">
        <p class="username" v-if="user?.username">{{ user.username }}</p>
        <el-button v-if="user?.username" size="small" type="danger" @click="logout">登出</el-button>
        <div v-else class="login-btn-box">
          <el-button size="small" type="primary" @click="login">登录</el-button>
          <router-link to="/register">注册</router-link>
        </div>
          
      </div>
    </div>
    <div class="router-box">
      <router-view />
    </div>
  </div>
</template>

<style lang="less" scoped>
.out-container-app {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/back-app2.jpg");
  background-position: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 36px;
}
.logo-box {
  display: flex;
  align-items: center;

  .logo {
    width: 36px;
    height: 36px;
  }


  a {
    text-decoration: none;
    font-size: 12px;
    font-weight: 800;
    margin-left: 12px;
    color: grey;
  }
}

.router-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.status-box {
  display: flex;
  justify-content: end;
  width: auto;
  align-items: center;

  .username {
    font-size: 12px;
    color: gray;
    margin-right: 20px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .login-btn-box {
    display: flex;
    width: 106px;
    justify-content: space-around;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 12px;
      color: darkslategrey;
    }
  }
}
</style>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import router from '@/router';

export default {
  setup() {
    const store = useStore();

    const user = computed(() => store.getters.getUser);

    const login = async () => {
      router.push('/login');
    };

    const goToHome = () => {
      router.push('/');
    }

    store.dispatch('fetchUser');

    const logout = () => {
      store.dispatch('logout');
    };

    return { user, login, logout, goToHome };
  },
};
</script>
