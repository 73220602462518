<template>
  <div class="outer">
    <chapter-details />
  </div>
</template>

<script>
import ChapterDetails from '@/components/novel/ChapterDetails.vue'

export default {
  name: 'ChapterView',
  components: {
    ChapterDetails
  }
}
</script>

<style lang="less" scoped>
.outer {
  height: 100%;
  overflow: hidden;
}
</style>