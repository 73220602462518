<template>
  <div 
    class="container" 
    id="chapter-container-root"
    :style="`background-color: ${backgroundColor === '#ffffff' ? 'transparent' : backgroundColor}`"
  >
    <el-skeleton 
      v-if="isJumping" 
      :rows="10" 
      animated 
    />
    <div
      v-else
      class="novel-chapter" 
      id="novel-chapter-box"
      :style="`background-color: ${backgroundColor === '#ffffff' ? 'rgba(255, 255, 255, 0.9)' : backgroundColor}; color: ${fontColor};`"
    >
      <el-button class="setting-btn" :icon="Operation" circle size="large" @click="() => drawerVisible = !drawerVisible">
      </el-button>
      <div class="heading-box">
        <h1 id="novel-chapter-title">{{ chapter.title }}</h1>
      </div>
      <div id="novel-chapter-content" style="line-height: 1.75;" v-html="chapter.content"></div>
      <div v-if="quizzes.length > 0" class="quiz-section">
        <hr :style="`border-color: ${fontColor === '#000000' ? 'gray' : fontColor}`"/>
        <div v-for="(quiz, index) in quizzes" :key="index" class="quiz-item">
          <p :style="`color: ${fontColor === '#000000' ? 'burlywood' : fontColor}`">{{ quiz.question_text }}</p>
          <ul>
            <li 
              v-for="(option, optIndex) in quiz.options" 
              :key="optIndex" 
              @click="() => redirectToChapter(option)"
              :style="`border-color: ${fontColor === '#000000' ? 'gray' : fontColor}`"
            >
              {{ option.option_text }}{{ option.notes ? ` - (${option.notes})` : '' }}
            </li>
          </ul>
        </div>
      </div>
      <p class="tips" :style="`color: ${fontColor === '#000000' ? 'gray' : fontColor}`" v-else>--此故事线已完结--</p>
      <div v-if="ad?.title ?? undefined" class="ad-box">
        <span>推广</span>
        <el-icon class="link-icon"><Link /></el-icon>
        <a class="ad-link" @click="() => handleClickAd(chapter.novel_id, chapter.id, ad)">
          <img :src="`${BASE_URL}${ad?.source_url}`" :alt="ad.title" class="ad-img" />
        </a>
      </div>
    </div>
    <el-drawer
      title="页面设置"
      v-model="drawerVisible"
      direction="btt"
      size="320px;"
    >
      <div class="drawer-body">
        <el-row justify="center" class="theme-switcher-row" :gutter="20">
          <el-col 
            style="width: 100%; height: 100%;" 
            :xs="24" :sm="24" :md="8" :lg="6" 
            v-for="(theme, index) in themes" 
            :key="index"
          >
            <div
              class="theme-btn"
              :style="{ backgroundColor: theme.backgroundColor, color: theme.fontColor }"
              @click="() => switchTheme(theme.id)"
            >
              {{ theme.name }}
          </div>
          </el-col>
        </el-row>
        <div>
          <label for="fontSize" style="font-size: 12px; font-weight: 600; margin-bottom: 12px;">字体大小:</label>
          <el-slider v-model="fontSize" @change="applyStyles" :min="12" :max="36"></el-slider>
        </div>
        <div style="margin-top: 20px; display: flex; align-items: center;">
          <el-button size="small" @click="() => resetStyle()">重置颜色/字体</el-button>
          <a 
            style="font-size: 12px; font-weight: 600; margin-left: 12px; cursor: pointer;" 
            @click="() => handleClickNovel(chapter.novel_id)"
          >
            {{ chapter.novel_title }}
            <span style="color: brown;">（从第一章重新开始）</span>
          </a>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, nextTick, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { request, BASE_URL } from '@/utils/apiClient'
import { ElMessage } from 'element-plus';

import { Operation } from '@element-plus/icons-vue'

const router = useRouter();
const chapterId = ref(null);
const chapter = ref({});
const quizzes = ref([]);
const ad = ref({});
const isJumping = ref(true);
const drawerVisible = ref(false);
const backgroundColor = ref('#ffffff'); // 默认白色
const fontSize = ref(16); // 默认字体大小
const fontColor = ref('#000000');
const isLoadConfiging = ref(false);
const themes = [
      { id: 1, name: '清新主题', backgroundColor: '#f5f5dc', fontColor: '#333333' },
      { id: 2, name: '夜间模式', backgroundColor: '#2c3e50', fontColor: '#ffffff' },
      { id: 3, name: '森林绿意', backgroundColor: '#d4edda', fontColor: '#212529' },
      { id: 4, name: '经典黑白', backgroundColor: '#ffffff', fontColor: '#000000' },
    ];
    const switchTheme = async (themeId) => {
      try {
        const currentTheme = themes.find(theme => theme.id === themeId);
        backgroundColor.value = currentTheme?.backgroundColor ?? '#ffffff';
        fontColor.value = currentTheme?.fontColor ?? '#000000';
        await applyStyles();
      } catch (e) {
        ElMessage.error('发生了一些意料之外的错误，我们非常抱歉，请你重试之前的操作');
      }

    };

// 应用颜色和字体大小到页面
const applyStyles = async () => {
  if (isLoadConfiging.value) return;
  document.getElementById('novel-chapter-title').style.setProperty('font-size', `${fontSize.value * 1.75}px`);
  document.getElementById('novel-chapter-box').style.setProperty('background-color', backgroundColor.value);
  document.getElementById('app-root-bar').style.setProperty('background-color', backgroundColor.value);
  document.getElementById('novel-chapter-content').style.setProperty('font-size', `${fontSize.value}px`);
  document.getElementById('novel-chapter-box').style.setProperty('color', `${fontColor.value}`);
  await updateUserInterfaceConfig();
};

const resetStyle = async () => {
  backgroundColor.value = '#ffffff';
  fontSize.value = 16;
  fontColor.value = '#000000';
  await applyStyles();
}

async function handleClickNovel(novel) {
  try {
    isJumping.value = true
    const response = await request('api/novel/first_chapter/', 'get', {
      'novel_id': novel
    })

    if (!response?.data?.id) {
      ElMessage.info('作者还没有开始编写这部小说呢，暂时还看不了哦')  
    }

    ElMessage.info('已跳转到小说第一章');
    router.push(`/chapter/${response?.data?.id}/details`)
  } catch {
    ElMessage.info('发生了一些错误哦，我们会尽快解决')
  } finally {
    isJumping.value = false
  }
}

async function updateUserInterfaceConfig() {
  if (isLoadConfiging.value) return;
  const config = {
    backgroundColor: backgroundColor.value,
    fontColor: fontColor.value,
    fontSize: fontSize.value
  }

  try {
    await request('api/record/update_interface_config/', 'post', {}, {
      config: JSON.stringify(config)
    });
  } catch (err) {
    console.error(err);
  }
}

async function getUserInterfaceConfig() {
  isLoadConfiging.value = true;
  try {
    const res = await request('api/record/get_interface_config/')
    if (res.status === 200) {
      const config = res?.data?.config ?? {};
      fontSize.value = config?.fontSize ?? 16;
      fontColor.value = config?.fontColor ?? '#000000';
      backgroundColor.value = config?.backgroundColor ?? '#ffffff';
    }
  } catch(err) {
    console.error(err);
  } finally {
    isLoadConfiging.value = false;
    await applyStyles();
  }
}

onMounted(async () => {
  isJumping.value = true;
  chapterId.value = router?.currentRoute.value?.params?.id;
  await fetchChapterData();
});

async function fetchChapterData() {
  try {
    const response = await request('api/novel/question_by_chapter/', 'get', {
      'chapter_id': chapterId.value
    });
    const randomAd = await request('api/ad/random-ad/', 'get', {
      'chapter_id': chapterId.value,
      'novel_id': response?.data?.chapter?.novel_id
    });
    if (!randomAd?.data?.message) {
      ad.value = randomAd?.data
    }
    chapter.value = response?.data?.chapter;
    quizzes.value = response?.data?.quizzes?.map((q) => ({
      question_text: q.question_text,
      notes: q.notes,
      options: q.options.map((opt) => ({
        option_text: opt.option_text,
        notes: opt.notes,
        redirect_chapter_id: opt.redirect_chapter_id,
      })),
    }));
  } catch (e) {
    if (e?.response?.status === 401) {
      router.push(`/login?jump_to=${chapterId.value}&type=chapter`)
    } else {
      ElMessage.info('发生了一些错误哦，我们会尽快解决')
    }
  }
}

function redirectToChapter(option) {
  if (option.redirect_chapter_id) {
    router.push(`/chapter/${option.redirect_chapter_id}/details`);
  }
}

function scrollToTopOfContainer() {
  var container = document.getElementById('chapter-container-root');
  if (container) {
    container.scrollTop = 0;
  } else {
    console.error('Element with ID "chapter-container-root" not found.');
  }
}

async function handleClickAd(novelId, chapterId, ad) {
  try {
    await request('api/ad/add_click_record/', 'post', {}, {
      'ad_id': ad?.id,
      'chapter_id': chapterId,
      'novel_id': novelId
    });
  } catch(error) {
    console.error(error)
  } finally {
    window.open(ad?.link, "_blank");
  }
}

watchEffect(async () => {
  isJumping.value = true;
  chapterId.value = router?.currentRoute.value?.params?.id;
  await fetchChapterData();
  isJumping.value = false;
  await nextTick();
  await getUserInterfaceConfig();
  scrollToTopOfContainer();
}, []);

onUnmounted(() => {
  document.getElementById('app-root-bar').style.setProperty('background-color', '');
})
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
}

.header-box {
  background-color: antiquewhite;
  border-radius: 12px;
}

.novel-chapter {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 22px;
  max-width: 600px;
  border-radius: 12px;
  position: relative;
}

.heading-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-btn {
  position: fixed;
  z-index: 10;
  right: 40px;
  bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.tips {
  text-align: center;
  font-size: 12px;
  color: gray;
  margin-top: 30px;
}

.quiz-section {
  margin-top: 52px;

  h2 {
    font-weight: 600;
    line-height: 32px;
    color: burlywood;
  }

  hr {
    color: grey;
  }
}
.quiz-item {
  p {
    color: orange;
    font-weight: 600;
    font-size: 22px;
    margin-top: 32px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none; /* 移除项目符号 */
  }

  li {
    text-decoration: none;
    padding: 16px 22px;
    margin-bottom: 12px;
    border: 1px solid gray;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
  }
}

.ad-box {
  width: 100%;
  position: relative;
  margin-top: 50px;
  cursor: pointer;

  span {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .9);
    color: aliceblue;
    font-size: 12px;
  }

  .link-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
    color: aliceblue;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 6px;
    z-index: 9;
  }

  a > img {
    width: 100%;
  }

  .drawer-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

.theme-switcher-row {
  margin-bottom: 20px;
}

.theme-btn {
  height: 56px !important;
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  font-size: 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid blanchedalmond;
}
</style>
