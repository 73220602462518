<template>
  <div class="login-form">
    <el-form @submit.prevent="handleLogin" label-position="top">
      <el-form-item label="用户名">
        <el-input v-model="loginData.username" />
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="loginData.password" show-password/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { request, setToken } from '@/utils/apiClient';
import { ElMessage } from 'element-plus';
import router from '@/router';
import { useRoute } from 'vue-router';
import store from '@/store';

export default {
  setup() {
    const loginData = ref({
      username: '',
      password: '',
    });

    const route = useRoute();

    const handleLogin = async () => {
      try {
        const response = await request('api/user/login/', 'post', {}, loginData.value);
        if (response?.status === 200) {
          ElMessage.success('登录成功');
          setToken(response?.data?.token);
          store.dispatch('fetchUser');
          const jumpType = route?.query?.type;
          const jumpTo = route?.query?.['jump_to'];

          if (jumpType === 'chapter') {
            router.push(`/chapter/${jumpTo}/details`)
            return
          }

          if (jumpType === 'novel') {
            try {
              const res = await request('api/record/reading_process/', 'get', {
                'novel_id': jumpTo
              })

              if (!res?.data?.id) {
                router.push('/');  
              }

              router.push(`/chapter/${res?.data?.id}/details`)
            } catch (e) {
              if (e?.response?.status === 401) {
                router.push(`/login?jump_to=${jumpTo}&type=novel`)
              } else {
                router.push('/');
              }
            }

            return;
          }

          router.push('/');
        } else {
          ElMessage.error(response?.data?.message || '登录失败');
        }
      } catch (error) {
        console.error('登录错误:', error);
        ElMessage.error('登录时发生错误');
      }
    };

    return {
      loginData,
      handleLogin,
    };
  },
};
</script>