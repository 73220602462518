import router from '@/router';
import axios from 'axios';
import { ElMessage } from 'element-plus';

export const BASE_URL = 'https://api-novel.x-technology.net.cn'
// export const BASE_URL = 'http://localhost:8000'

const getToken = () => {
  return localStorage.getItem('novel-token') || '';
}

export const setToken = (token = '') => {
  localStorage.setItem('novel-token', token);
}

export const request = async (url, method = 'get', params = {}, data = {}, headers = {}) => {
  if (!url) {
    throw new Error('url cannot be an empty string!');
  }

  const methods = ['get', 'post', 'put', 'delete', 'patch'];
  if (!methods.includes(method.toLowerCase())) {
    throw new Error('Invalid HTTP method');
  }

  const axiosMethod = axios;
  if (!axiosMethod) {
    throw new Error('Unsupported HTTP method');
  }

  const fromData = new URLSearchParams()
  Object.keys(data).forEach(key => {
    fromData.append(key, data[key])
  })

  try {
    const response = await axiosMethod({
      url: `${BASE_URL}/${url}`,
      method,
      params,
      data: fromData,
      headers: { ...headers, token: getToken() }
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized request, token might have expired.');
      router.push('/login');
      ElMessage.info('要继续操作请先登录哦')
    } else {
      console.error('Request failed:', error.response?.data || error.message);
    }

    throw error; // 重新抛出错误，以便调用者可以处理
  }
};
